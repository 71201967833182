import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { components } from "../api/api";
import { getMainchainHistory } from "../api/history";

interface IMainchainHistorySlice {
  entries: components["schemas"]["MainchainHistoryEntry"][];
  hasMore: boolean;
  loading: boolean;
  currentPage: number;
}

const initialState: IMainchainHistorySlice = {
  entries: [],
  hasMore: true,
  loading: false,
  currentPage: 0,
};

export const fetchMainchainHistory = createAsyncThunk(
  "mainchainHistory/fetchMainchainHistory",
  async ({ address, page }: { address: string; page: number }) => {
    return getMainchainHistory(address, page);
  },
);

const mainchainHistorySlice = createSlice({
  name: "mainchainHistory",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMainchainHistory.pending, (state, action) => {
        state.loading = true;
        state.currentPage = action.meta.arg.page;
      })
      .addCase(fetchMainchainHistory.fulfilled, (state, { payload }) => {
        state.entries = (payload.entries ?? []).map(entry =>
          entry.assetSymbol === "WETH" ? { ...entry, assetSymbol: "ETH" } : entry,
        );
        state.hasMore = payload.more || false;
        state.loading = false;
      })
      .addCase(fetchMainchainHistory.rejected, state => {
        state.loading = false;
      });
  },
});

export default mainchainHistorySlice.reducer;
