import { get } from "../helpers/request";
import { components } from "./api";

export const getMainchainHistory = (address: string, page: number, limit = 20) => {
  return get(`/users/${address}/mainchainHistory?page=${page}&limit=${limit}`) as Promise<
    components["schemas"]["MainchainHistory"]
  >;
};

export const getInvestmentHistory = (address: string, page: number, limit = 20) => {
  return get(`/users/${address}/investmentHistory?page=${page}&limit=${limit}`) as Promise<
    components["schemas"]["InvestmentHistory"]
  >;
};

export const getFarmingHistory = (address: string, page: number, limit = 20) => {
  return get(`/users/${address}/farmingHistory?page=${page}&limit=${limit}`) as Promise<
    components["schemas"]["FarmingHistory"]
  >;
};
