import { get, post } from "../helpers/request";
import { components } from "./api";

export type FarmingPoolStatus = "ACTIVE" | "ENDED";

export async function getFarmingPools(addr: string) {
  const res = await get<components["schemas"]["FarmingPools"]>(`/farmingPools?user=${addr}`);
  return res.farmingPools;
}

export async function farmingOperation(params: components["schemas"]["FarmingOperationParams"]) {
  const res = await post<components["schemas"]["FarmingOperationParams"]>("/farmingOperation", params);
  return res;
}
