import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { components } from "../api/api";
import { getFarmingPools } from "../api/farming";

export type FarmingPool = components["schemas"]["FarmingPool"] & { apy: number };
export interface IFarmingState {
  pools: FarmingPool[];
  loading: boolean;
  actionLoading: boolean;
}

const initialState: IFarmingState = {
  pools: [],
  loading: false,
  actionLoading: false,
};

export const fetchPools = createAsyncThunk("farmingPool/fetchPools", async (address: string, thunkApi) => {
  thunkApi.dispatch(setLoading(true));
  thunkApi.dispatch(clear());
  const pools = await getFarmingPools(address);
  if (pools) {
    thunkApi.dispatch(save(pools));
  }
  thunkApi.dispatch(setLoading(false));
});

const farmingPoolSlice = createSlice({
  name: "farmingPool",
  initialState,
  reducers: {
    save: (state, { payload }: PayloadAction<components["schemas"]["FarmingPool"][]>) => {
      // calculate total apy and inject into pool data
      state.pools = payload.map(pool => {
        const apy = pool.farmingAssets.reduce((totalApy, current) => totalApy + current.apy, 0);
        return { ...pool, apy };
      });
    },
    clear: state => {
      // calculate total apy and inject into pool data
      state.pools = initialState.pools;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setActionLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.actionLoading = payload;
    },
  },
});

export const { save, setLoading, setActionLoading, clear } = farmingPoolSlice.actions;

export default farmingPoolSlice.reducer;
