import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { components } from "../api/api";
import { getInvestmentHistory } from "../api/history";

interface IInvestmentHistorySlice {
  entries: components["schemas"]["InvestmentHistoryEntry"][];
  hasMore: boolean;
  loading: boolean;
  currentPage: number;
}

const initialState: IInvestmentHistorySlice = {
  entries: [],
  hasMore: true,
  loading: false,
  currentPage: 0,
};

export const fetchInvestmentHistory = createAsyncThunk(
  "investmentHistory/fetchInvestmentHistory",
  async ({ address, page }: { address: string; page: number }) => {
    return getInvestmentHistory(address, page);
  },
);

const historySlice = createSlice({
  name: "investmentHistory",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchInvestmentHistory.pending, (state, action) => {
        state.loading = true;
        state.currentPage = action.meta.arg.page;
      })
      .addCase(fetchInvestmentHistory.fulfilled, (state, { payload }) => {
        state.entries = payload.entries || [];
        state.hasMore = payload.more || false;
        state.loading = false;
      })
      .addCase(fetchInvestmentHistory.rejected, state => {
        state.loading = false;
      });
  },
});

export default historySlice.reducer;
