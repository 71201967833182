import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatUnits } from "ethers/lib/utils";
import { components } from "../api/api";
import { getAssets } from "../api/asset";

interface ISliceState {
  assets: components["schemas"]["Asset"][];
  selectedIndex: number;
  loading: boolean;

  // derived states
  tvl: number; // string encoded BigNumber
}

const initialState: ISliceState = {
  assets: [],
  selectedIndex: 1000,
  loading: false,
  tvl: 0,
};

export const fetchAssets = createAsyncThunk("asset/fetchAssets", async (address: string) => {
  return getAssets(address).catch(_ => null);
});

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    setSelectedToken: (state, { payload }: PayloadAction<number>) => {
      const index = state.assets.findIndex(token => {
        return token.id === payload;
      });
      if (index !== -1) {
        state.selectedIndex = index;
      }
    },
    setSelectedTokenIndex: (state, { payload }: PayloadAction<number>) => {
      state.selectedIndex = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAssets.pending, state => {
        state.loading = true;
      })
      .addCase(fetchAssets.rejected, state => {
        state.loading = false;
      })
      .addCase(fetchAssets.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload && payload.length) {
          const assets = payload.map(asset => {
            if (asset.symbol === "WETH") {
              asset.symbol = "ETH";
              asset.name = "ETH";
            }
            return asset;
          });
          state.assets = assets;
          state.tvl = assets.reduce((total, asset) => {
            const subtotal = total + parseFloat(formatUnits(asset.tvl || 0, asset.decimal)) * (asset.price || 0);
            return subtotal;
          }, 0);
        }
      });
  },
});

export const { setSelectedToken, setSelectedTokenIndex } = assetSlice.actions;

export default assetSlice.reducer;
